.span-label {
    top: -0.5em;
    padding: 2px 3px;
    z-index: 10;
    position: absolute;
    font-size: 0.6em;
    background: #ffe184;
    font-weight: bold;
    font-family: "Roboto Condensed", "Arial Narrow", sans-serif;
    line-height: 1;
    border-radius: 3px;
}

.label-line {
    top: 40px;
    left: -1px;
    width: calc(100% + 2px);
    height: 2px;
    cursor: pointer;
    position: absolute;
    background: #ffe184;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.text-container {
    line-height: 2;
    font-size: 20px;
    padding: 20px;
}


.token-block {
    margin: 0 1px;
    display: inline-block;
    position: relative;
    white-space: pre-wrap;
}


.label-head {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

