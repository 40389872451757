.document-chip:hover {
  background-color: #ececec !important; /* Light grey background on hover */
  cursor: pointer; /* Changes the cursor to indicate the item is interactive */
}

.selected-chip {
  background-color: #6872ff !important; /* Light grey background when selected */
  color: white !important; /* White text when selected */
}

.card-container {
  position: relative;
  padding: 16px;
  margin: 8px;
  margin-top: 24px;
  border: 1px solid;
}

.bottom-right-text {
  position: absolute;
  bottom: 8px;
  right: 16px;
  color: #555; /* Adjust color as needed */
}
