#container {
    overflow-y: hidden;
    display: flex;
    flex-flow: column nowrap;
    /* justify-content: flex-end; DO NOT USE: breaks scrolling */
}

.nav-bar{
    border: 1px solid grey;
    border-radius: 5px;
    margin-top: 2px;
}

.search-form {
    margin: 3px;
}

.input-field {
    max-width: 30%;
    margin: 0;
}
.container {
    margin: 2px;
}

#btn-style1 {
    margin: 2px;
    background-color: #e24c59;
    border-color: #e24c59;
    color: white;
}

#btn-style2 {
    margin: 2px;
    background-color: #e25449;
    border-color: #e25449;
    color: white;
}

#btn-style3 {
    margin: 2px;
    background-color: #ef7148;
    border-color: #ef7148;
    color: white;
}

#btn-style4 {
    margin: 2px;
    background-color: #f8c64d;
    border-color: #f8c64d;
    color: white;
}

#btn-style5 {
    margin: 2px;
    background-color: #b9cb5d;
    border-color: #b9cb5d;
    color: white;
}

#btn-style6 {
    margin: 2px;
    background-color: #8bc95b;
    border-color: #8bc95b;
    color: white;
}

#btn-style7 {
    margin: 2px;
    background-color: #3fbc64;
    border-color: #3fbc64;
    color: white;
}

#btn-style8 {
    margin: 2px;
    background-color: #40c4b5;
    border-color: #40c4b5;
    color: white;
}

#btn-style9 {
    margin: 2px;
    background-color: #41b9dc;
    border-color: #41b9dc;
    color: white;
}

#btn-style10 {
    margin: 2px;
    background-color: #878de4;
    border-color: #878de4;
    color: white;
}

#btn-style11 {
    margin: 2px;
    background-color: #bd83e0;
    border-color: #bd83e0;
    color: white;
}

.line-p {
    margin: 0;
}

.example-name-text {
    font-weight: bold;
    font-style: italic;
    /*text-decoration-color: orange;*/
}


.full-length {
    width: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-display {
    z-index: 3;
}

.MuiTablePagination-selectLabel {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0;    
}


.MuiTablePagination-displayedRows {
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;    
}