.custom-completionInfo .cm-tooltip-autocomplete{
	z-index: 2;
}
.Resizer {
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;
}

.cm-matchingBracket {
  background-color: green !important; /* Use a bright background color */
}

.cm-nonmatchingBracket {
  background-color: red !important; /* Use a different color for non-matching brackets */
}