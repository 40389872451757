.hljs-variable {
  color: #008080;
}
.hljs-string {
  color: #954121;
}
.hljs-symbol {
  color: #990073;
}
.hljs-comment {
  color: #408080;
}
.hljs-literal {
  color: #40a070;
}
.hljs-punctuation {
  color: #000080;
}