.radio-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    position: relative;
    cursor: pointer;
}

.radio-button-group {
    padding-top: 10px;
}

span {
    white-space:pre-line;
}


.radio-button:hover .tooltip {
  display: block;
}

.tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0.9;
}